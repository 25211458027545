export const imageMap: Record<string, Record<string, string>> = {
  sin: {
    0: "0",
    30: "1over2",
    45: "1overroot2",
    60: "root3over2",
    90: "1",
    120: "root3over2",
    135: "1overroot2",
    150: "1over2",
    180: "0",
    210: "neg1over2",
    225: "neg1overroot2",
    240: "negroot3over2",
    270: "neg1",
    300: "negroot3over2",
    315: "neg1overroot2",
    330: "neg1over2",
    360: "0",
  },
  cos: {
    0: "1",
    30: "root3over2",
    45: "1overroot2",
    60: "1over2",
    90: "0",
    120: "neg1over2",
    135: "neg1overroot2",
    150: "negroot3over2",
    180: "neg1",
    210: "negroot3over2",
    225: "neg1overroot2",
    240: "neg1over2",
    270: "0",
    300: "1over2",
    315: "1overroot2",
    330: "root3over2",
    360: "1",
  },
  tan: {
    0: "0",
    30: "1overroot3",
    45: "1",
    60: "root3",
    90: "dne",
    120: "negroot3",
    135: "neg1",
    150: "neg1overroot3",
    180: "0",
    210: "1overroot3",
    225: "1",
    240: "root3",
    270: "dne",
    300: "negroot3",
    315: "neg1",
    330: "neg1overroot3",
    360: "0",
  },
  csc: {
    0: "dne",
    30: "2",
    45: "root2",
    60: "2overroot3",
    90: "1",
    120: "2overroot3",
    135: "root2",
    150: "2",
    180: "dne",
    210: "neg2",
    225: "negroot2",
    240: "neg2overroot3",
    270: "neg1",
    300: "neg2overroot3",
    315: "negroot2",
    330: "neg2",
    360: "dne",
  },
  sec: {
    0: "1",
    30: "2overroot3",
    45: "root2",
    60: "2",
    90: "dne",
    120: "neg2",
    135: "negroot2",
    150: "neg2overroot3",
    180: "neg1",
    210: "neg2overroot3",
    225: "negroot2",
    240: "neg2",
    270: "dne",
    300: "2",
    315: "root2",
    330: "2overroot3",
    360: "1",
  },
  cot: {
    0: "dne",
    30: "root3",
    45: "1",
    60: "1overroot3",
    90: "0",
    120: "neg1overroot3",
    135: "neg1",
    150: "negroot3",
    180: "dne",
    210: "root3",
    225: "1",
    240: "1overroot3",
    270: "0",
    300: "neg1overroot3",
    315: "neg1",
    330: "negroot3",
    360: "dne",
  },
};
